var default_width = window.innerWidth;//アクセスした時の横幅を取得 スクロールバーを含めた値
var resize_width = window.innerWidth;
var break_point = 767;
$(function() {
	var default_height = $(document).innerHeight();//アクセスした時の高さを取得
  /*------------------------*/
  //リンクの範囲を広くする
  /*------------------------*/
  $(".widelink").on('click',function(){
    window.location=$(this).find("a").attr("href");
    return false;
	});
  barFix();//追従処理
  barFixBengo();//追従処理-事務所詳細

  //スムーススクロール（ページ内リンク）
  $('.tocList__item a[href^="#"],.js-scroll').on("click", function () {
    var f = 600;
    var e = $(this).attr("href");
    var g = $(e == "#" || e == "" ? "html" : e);
    var d = g.offset().top;
    $("html, body").animate({
      scrollTop: d
    }, f, "swing");
    return false
  });

  //目次開閉
  var flg = "open";
  $(".toc_toggle").on('click',function(){
    $(".tocList").slideToggle();
    if (flg == "close") {
      $(this).text("[非表示]");
      flg = "open";
    } else {
      $(this).text("[表示]");
      flg = "close";
    }
  });

  //tab
  $('.tabMenu .tabMenu__item').on('click',function() {
    var i = $(this).index();
    // クリック済みタブのデザインを設定したcssのクラスを一旦削除
    $(this).siblings().removeClass('active');
    // クリックされたタブにクリック済みデザインを適用する
    $(this).addClass('active');
    // コンテンツを一旦非表示にし、クリックされた順番のコンテンツのみを表示
    $(this).parent().next(".tabItem").children(".tabList").fadeOut(200).removeClass("show").eq(i).fadeIn(200).addClass("show");
  });

  //accordion
  $(".js-accordion .js-accordion__btn").on('click',function() {
    $(this).toggleClass("open");
    $(this).next(".js-accordion__item").slideToggle();
  });

  //pulldown
  $(".js-pulldown .js-pullDownList").on('click',function() {
    $(this).toggleClass("is-show").find(".js-pullDownItem").not(":first").slideToggle();
  });

  //追従バナー
  var fixB = $(".fixedBnr");
  if (fixB.length) {
    fixB.hide();
    $(window).on('scroll',function () {
      var doch = $(document).innerHeight(), //ページ全体の高さ
      winh = $(window).innerHeight(), //ウィンドウの高さ
      bottom = doch - winh, //ページ全体の高さ - ウィンドウの高さ = ページの最下部位置
      footh = $(".footer").innerHeight();
      if ($(this).scrollTop() > 100 && $(this).scrollTop() < bottom - footh) {
        fixB.fadeIn();
      } else {
        fixB.fadeOut();
      }
    });
  }

  /*----------------------------------------------------*/
  /* 検索エリアのパネルを表示
  /*----------------------------------------------------*/
  var area = {name : "エリアを変更する" , val : ""};
  var cat = {name : "相談内容を選択" , val : ""};
  $("body").on('click', '.js-searchInput', function() {
    if ($(this).attr('data-area')) {
      area.name = $(this).text();
      area.val = $(this).attr('data-area');
    }
    if ($(this).attr('data-cat')) {
      cat.name = $(this).text();
      cat.val = $(this).attr('data-cat');
    }
    if (area.val == "") {
      $('.btn a').attr('href',"/lawyer" + cat.val);
    } else {
      $('.btn a').attr('href',area.val + cat.val);
    }
    $('.ken .input').text(area.name);
    $('.naiyo .input').text(cat.name);
    $('#overlay').fadeOut(200);
    $('#naiyolist, #kenlist').css({display:'none'});
    $("#naiyo, #ken, #geo").removeClass('on');
  });
  //エリアのほう
  $(".js-areaopen").on('click',function() {
    $("#naiyo").removeClass('on');
    $('#kenlist').css({display:'block'});
    $('#naiyolist').css({display:'none'});
    if($("#ken").hasClass('on')) {
      $('#overlay').fadeOut(200);
      $('#naiyolist, #kenlist').css({display:'none'});
      $("#ken").removeClass('on');
    } else {
      $("#ken").addClass('on');
    }
    if (default_width >= break_point) {
      $('#overlay').css({display:'block'});
    }
  });
  //ご相談内容のほう
  $(".js-naiyoopen").on('click',function() {
    $("#ken").removeClass('on');
    $('#naiyolist').css({display:'block'});
    $('#kenlist').css({display:'none'});
    if($("#naiyo").hasClass('on')) {
      $('#overlay').fadeOut(200);
      $('#naiyolist, #kenlist').css({display:'none'});
      $("#naiyo").removeClass('on');
    } else {
      $("#naiyo").addClass('on');
    }
    if (default_width >= break_point) {
      $('#overlay').css({display:'block'});
    }
  });
  //エリア外クリックで非表示
  $("#overlay").on('click',function() {
    $('#overlay').fadeOut(200);
    $('#naiyolist, #kenlist').css({display:'none'});
    $("#naiyo, #ken").removeClass('on');
    if ($("#geo").hasClass("on")) {
      console.log("aaa");
      $('#kenlist li a').replaceWith(function() { // span要素をa要素に書き換え
        var this_data = $(this).attr("data-area");
        if ( this_data == null) {
          var this_data_plus =[];
        } else {
          var this_data_plus = 'data-area="'+this_data+'"';
        }
        $(this).replaceWith('<span class="js-searchInput" '+this_data_plus+'>'+$(this).html()+'</span>');
      });
      $("#geo").removeClass('on');
    }
  });

  //エリアのほう
  $("#geo").on('click',function() {
    if (default_width >= break_point) {
      $('#kenlist li span').replaceWith(function() { // span要素をa要素に書き換え
        var this_data = $(this).attr("data-area");
        if ( this_data == null) {
          var this_data_plus =[];
        } else {
          var this_data_plus = 'data-area="'+this_data+'"';
        }
        $(this).replaceWith('<a href="" '+this_data_plus+'>'+$(this).html()+'</a>');
      });
      $('#kenlist li a').removeClass("js-searchInput");
      $('#kenlist, #overlay').css({display:'block'});
      $('#kenlist li a').each(function() {
        var namehref = $(this).attr('data-area');
        var namearchive = $("#geo").attr('class');
        $(this).attr("href", namehref + '?c=' + namearchive);
      });
      if($("#geo").hasClass('on')) {
        $('#overlay').fadeOut(200);
        $('#kenlist').css({display:'none'});
        $("#geo").removeClass('on');
      } else {
        $("#geo").addClass('on');
      }
    } else {
      var position = $(".pref").offset().top - $(".header").height();
      $("html,body").animate({
        scrollTop : position
      }, {
        queue : false
      });
    }
  });

  /*----------------------------------------------------*/
  /* [PC][SP]UseragentでJSを切り替え
  /*----------------------------------------------------*/
  var getDevice = (function() {
  var ua = navigator.userAgent;
    if (!(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
      /*-------------------------------------------------------------------------*/
      /* PCの時
      /*-------------------------------------------------------------------------*/
      $(".widelink").hover(
        function(e) {
            $(this).addClass("hover");
        },
        function(e) {
            $(this).removeClass("hover");
        }
      );
    }
  })();

	/*------------------------*/
	//バーガーメニュー表示・非表示
	/*------------------------*/
	//バーガーボタンクリック
	$(".burger").on('click',function() {
		default_width = window.innerWidth;//クリックされた時の数値をdefault_widthに設定
		if (default_width <= break_point) {
      $(".burger").toggleClass("is-open");
      if ($(".gNav").hasClass("show")) {
        $(".gNav").removeClass("show").addClass("hide");
      } else if ($(".gNav").hasClass("hide")) {
        $(".gNav").removeClass("hide").addClass("show");
      } else {
        $(".gNav").addClass("show");
      }
			if ($(this).hasClass("is-open")) { //バーガーメニューが開かれた状態か
        $(".headerNav__cover").height(window.innerHeight-54+"px").removeClass("hide").addClass("show");
			} else {
        $(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			}
		}
	});
  //カバーをクリック
	$(".headerNav__cover,.gNav a").on('click',function() {
    if (default_width <= break_point) {
  		$(".gNav").removeClass("show").addClass("hide");
  		$(".burger").removeClass("is-open");
      $(".headerNav__cover").addClass("hide").removeClass("show");
    }
	});
  
	/*------------------------*/
	//リサイズ時の処理（リサイズした瞬間に走る）
	/*------------------------*/
	$(window).on('resize',function() {
		if (default_width == window.innerWidth){//アクセスした時と、リサイズした時のウィンドウ幅が同じかどうかを判定
			return;//アクセスした時と、リサイズした時のウィンドウ幅が同じだった時に処理を抜ける
		} else {
			default_width = window.innerWidth;//リサイズ処理がされた場合、default_widthの数値を更新
			resize_width = window.innerWidth;//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ時）
				/*------------------------*/
        //バーガーメニューを開いたままリサイズされた時の処理
        $(".gNav").removeClass("show hide");
				$(".burger").removeClass("is-open");
				$(".headerNav__cover").height(0).addClass("hide").removeClass("show");
			} else {
				/*------------------------*/
				//PC処理（リサイズ時）
				/*------------------------*/
        $(".gNav").removeClass("show hide");
			}
		}
	});

	/*------------------------*/
	//リサイズ完了時点処理（完了するまで処理されない）
	/*------------------------*/
	var timer = false;
	$(window).on('resize',function() {
		if (timer !== false) {
				clearTimeout(timer);
		}
		timer = setTimeout(function() {
			resize_width = $(window).innerWidth();//リサイズが完了したらウィンドウサイズの更新
			if (resize_width <= break_point) {
				/*------------------------*/
				//スマホ処理（リサイズ完了時）
        /*------------------------*/
        // $header.removeClass(scrollClass);
			} else {
				/*------------------------*/
				//PC処理（リサイズ完了時）
        /*------------------------*/
			}
		}, 200);
	});
});

/*----------------------------------------------------*/
/* サイドバー追従
/*----------------------------------------------------*/
var barFix = function() {
  //該当のセレクタなどを代入
  var mainArea = $(".layout--2col__cont"); //メインコンテンツ
  var sideWrap = $(".sideNav"); //サイドバーの外枠
  var sideArea = $(".sideNav__inner"); //サイドバー
  var wd = $(window); //ウィンドウ自体

  //メインとサイドの高さを比べる 
  var mainH = mainArea.innerHeight();
  var sideH = sideWrap.innerHeight();
  if(sideH < mainH && default_width >= break_point) { //メインの方が高ければ色々処理する
    //サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
    sideWrap.css({"height": mainH,"position": "relative"});
    //サイドバーがウィンドウよりいくらはみ出してるか
    var sideOver = wd.height()-sideArea.height();
    //固定を開始する位置 = サイドバーの座標＋はみ出す距離
    var starPoint = sideArea.offset().top + (-sideOver);
    //固定を解除する位置 = メインコンテンツの終点
    var breakPoint = sideArea.offset().top + mainH;
    wd.on('scroll', function() { //スクロール中の処理
      if(wd.height() < sideArea.height()){ //サイドメニューが画面より大きい場合
        if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
          sideArea.css({"position": "fixed", "bottom": "0"}); 
        }else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
          sideArea.css({"position": "absolute", "bottom": "0"});
        } else { //その他、上に戻った時
          sideArea.css("position", "static");
        }
      }else{ //サイドメニューが画面より小さい場合
        var sideBtm = wd.scrollTop() + sideArea.height(); //サイドメニューの終点
        if(mainArea.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
          sideArea.css({"position": "fixed", "top": 20});
        }else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
          //サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
          var fixedSide = mainH - sideH;
          sideArea.css({"position": "absolute", "top": fixedSide});
          } else {
            sideArea.css("position", "static");
          }
        }
    });
  } else if (default_width <= break_point) {
    sideArea.css({"height": "auto","position": "static"});
    sideWrap.css({"height": "auto","position": "static"});
  }
  wd.on('resize', function() {
    sideArea.css({"height": "auto","position": "static"});
    sideWrap.css({"height": "auto"});
    mainH = mainArea.innerHeight();
    sideH = sideWrap.innerHeight();
    if(sideH < mainH && default_width >= break_point) { //メインの方が高ければ色々処理する
      //サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
      sideWrap.css({"height": mainH,"position": "relative"});
      //サイドバーがウィンドウよりいくらはみ出してるか
      sideOver = wd.height()-sideArea.height();
      //固定を開始する位置 = サイドバーの座標＋はみ出す距離
      starPoint = sideArea.offset().top + (-sideOver);
      //固定を解除する位置 = メインコンテンツの終点
      breakPoint = sideArea.offset().top + mainH;
      wd.on('scroll', function() { //スクロール中の処理
        if(wd.height() < sideArea.height()){ //サイドメニューが画面より大きい場合
          if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
            sideArea.css({"position": "fixed", "bottom": "0"}); 
          }else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
            sideArea.css({"position": "absolute", "bottom": "0"});
          } else { //その他、上に戻った時
            sideArea.css("position", "static");
          }
        }else{ //サイドメニューが画面より小さい場合
          sideBtm = wd.scrollTop() + sideArea.height(); //サイドメニューの終点
          if(mainArea.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
            sideArea.css({"position": "fixed", "top": 20});
          }else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
            //サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
            fixedSide = mainH - sideH;
            sideArea.css({"position": "absolute", "top": fixedSide});
            } else {
              sideArea.css("position", "static");
            }
          }
      });
    } else if (default_width <= break_point) {
      sideArea.css({"height": "auto","position": "static"});
      sideWrap.css({"height": "auto","position": "static"});
    }
  });
}

/*----------------------------------------------------*/
/* サイドバー追従--事務所詳細
/*----------------------------------------------------*/
var barFixBengo = function() {
  //該当のセレクタなどを代入
  var mainAreaBengo = $(".bengoDetail--2col"); //メインコンテンツ
  var sideWrapBengo = $(".bengoInfo"); //サイドバーの外枠
  var sideAreaBengo = $(".bengoInfo__inner"); //サイドバー
  var wd = $(window); //ウィンドウ自体

  //メインとサイドの高さを比べる 
  var mainH = mainAreaBengo.innerHeight();
  var sideH = sideWrapBengo.innerHeight();
  if(sideH < mainH && default_width >= 1025) { //メインの方が高ければ色々処理する
    //サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
    sideWrapBengo.css({"height": mainH,"position": "absolute"});
    //サイドバーがウィンドウよりいくらはみ出してるか
    var sideOver = wd.height()-sideAreaBengo.height();
    //固定を開始する位置 = サイドバーの座標＋はみ出す距離
    var starPoint = sideAreaBengo.offset().top + (-sideOver);
    //固定を解除する位置 = メインコンテンツの終点
    var breakPoint = sideAreaBengo.offset().top + mainH;
    wd.on('scroll', function() { //スクロール中の処理
      if (default_width >= 1025) {
        if(wd.height() < sideAreaBengo.height()){ //サイドメニューが画面より大きい場合
          if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
            sideAreaBengo.css({"position": "fixed", "bottom": "0"}); 
          }else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
            sideAreaBengo.css({"position": "absolute", "bottom": "0"});
          } else { //その他、上に戻った時
            sideAreaBengo.css("position", "static");
          }
        } else { //サイドメニューが画面より小さい場合
          var sideBtm = wd.scrollTop() + sideAreaBengo.height(); //サイドメニューの終点
          if(mainAreaBengo.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
            sideAreaBengo.css({"position": "fixed", "top": 20});
          }else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
            //サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
            var fixedSide = mainH - sideH;
            sideAreaBengo.css({"position": "absolute", "top": fixedSide});
          } else {
            sideAreaBengo.css("position", "static");
          }
        }
      }
    });
  } else if (default_width <= 1025) {
    sideAreaBengo.css({"height": "auto","position": "static"});
    sideWrapBengo.css({"height": "auto","position": "static"});
  }
  wd.on('resize', function() {
    sideAreaBengo.css({"height": "auto","position": "static"});
    sideWrapBengo.css({"height": "auto"});
    mainH = mainAreaBengo.innerHeight();
    sideH = sideWrapBengo.innerHeight();
    if(sideH < mainH && default_width >= 1025) { //メインの方が高ければ色々処理する
      //サイドバーの外枠をメインと同じ高さにしてrelaltiveに（#sideをポジションで上や下に固定するため）
      sideWrapBengo.css({"height": mainH,"position": "absolute"});
      //サイドバーがウィンドウよりいくらはみ出してるか
      sideOver = wd.height()-sideAreaBengo.height();
      //固定を開始する位置 = サイドバーの座標＋はみ出す距離
      starPoint = sideAreaBengo.offset().top + (-sideOver);
      //固定を解除する位置 = メインコンテンツの終点
      breakPoint = sideAreaBengo.offset().top + mainH;
      wd.on('scroll', function() { //スクロール中の処理
        if (default_width >= 1025) {
          if(wd.height() < sideAreaBengo.height()){ //サイドメニューが画面より大きい場合
            if(starPoint < wd.scrollTop() && wd.scrollTop() + wd.height() < breakPoint){ //固定範囲内
              sideAreaBengo.css({"position": "fixed", "bottom": "0"}); 
            }else if(wd.scrollTop() + wd.height() >= breakPoint){ //固定解除位置を超えた時
              sideAreaBengo.css({"position": "absolute", "bottom": "0"});
            } else { //その他、上に戻った時
              sideAreaBengo.css("position", "static");
            }
          }else{ //サイドメニューが画面より小さい場合
            sideBtm = wd.scrollTop() + sideAreaBengo.height(); //サイドメニューの終点
            if(mainAreaBengo.offset().top < wd.scrollTop() && sideBtm < breakPoint){ //固定範囲内
              sideAreaBengo.css({"position": "fixed", "top": 20});
            }else if(sideBtm >= breakPoint){ //固定解除位置を超えた時
              //サイドバー固定場所（bottom指定すると不具合が出るのでtopからの固定位置を算出する）
              fixedSide = mainH - sideH;
              sideAreaBengo.css({"position": "absolute", "top": fixedSide});
              } else {
                sideAreaBengo.css("position", "static");
              }
            }
        }
      });
    } else if (default_width <= 1025) {
      sideAreaBengo.css({"height": "auto","position": "static"});
      sideWrapBengo.css({"height": "auto","position": "static"});
    }
  });
}